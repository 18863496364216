<template>
  <div style="padding: 20px">
    <div style="margin: 10px 0 20px 0">
      <el-input
          :size="size"
          v-model="query.commodityName"
          placeholder="商品名称"
          style="width: 160px;margin-right: 10px"
      ></el-input>
      <el-select
          :size="size"
          clearable
          v-model="query.commodityType"
          placeholder="商品分类"
      >
        <el-option
            v-for="(item, index) in productsClassify"
            :key="index"
            :label="item.dictLabel"
            :value="item.dictValue"
        ></el-option>
      </el-select>
      <el-button style="margin:0 10px" type="primary" @click="selectProductsList(0)">查 询</el-button>
      <el-button type="primary" @click="addProducts">新 增</el-button>
    </div>
    <el-table
        :data="productsList"
        border
        v-loading="loadingDia"
        :row-style="{height:10+'px'}"
        :cell-style="{padding:2+'px'}"
        class="table"
        ref="multipleTable"
        height="630px"
        header-cell-class-name="table-header"
    >
      <el-table-column width="140" show-overflow-tooltip="true" prop="commodityName" label="商品名称"/>
      <el-table-column width="140" show-overflow-tooltip="true" prop="commodityTypeName" label="商品分类"/>
      <el-table-column width="140" show-overflow-tooltip="true" prop="inventory" label="库存"/>
      <el-table-column width="170" show-overflow-tooltip="true" prop="startTime" label="上架日期"/>
      <el-table-column width="170" show-overflow-tooltip="true" prop="endTime" label="下架日期"/>
      <el-table-column width="100" show-overflow-tooltip="true" prop="purchasingPrice" label="进货价格"/>
      <el-table-column width="100" show-overflow-tooltip="true" prop="bazaarPrice" label="市场价格"/>
      <el-table-column width="100" show-overflow-tooltip="true" prop="scorePrice" label="积分购买"/>
      <el-table-column width="100" show-overflow-tooltip="true" prop="moneyPrice" label="金额购买"/>
      <el-table-column width="160" show-overflow-tooltip="true" label="积分&金额">
        <template v-slot="scope">
          {{scope.row.combineScorePrice}}积分 + {{scope.row.combineMoneyPrice}}金额
        </template>
      </el-table-column>
      <el-table-column width="140" align="center" label="缩略图">
        <template v-slot="scope">
          <el-image v-if="scope.row.pictures.length>0" class="code" :src="scope.row.pictures[0].url"
                    >
            <template #error>
              <div class="code"></div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column width="100" show-overflow-tooltip="true" prop="createName" label="创建人"/>
      <el-table-column label="操作" fixed="right" width="100" align="center">
        <template #default="scope">
          <el-button
              type="text"
              @click="updateItem(scope.row)"
          >编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>

  <add-products ref="addProducts"/>
  <update-position ref="updatePosition"/>
</template>

<script>
import {selectDictByType} from  "@/api/dict"
import addProducts from "./components/AddProducts";
import updatePosition from "./components/UpdateProducts";
import {selectProducts}from "@/api/mall.js";

export default {
  components: {
    addProducts,
    updatePosition
  },
  name: "Products",
  data() {
    return {
      size:'large',
      productsList:[],
      pageTotal:0,
      query:{
        pageIndex:0,
        pageSize:10,
        commodityName:"",
        commodityType:null
      }
    }
  },
  methods:{
    updateItem(item){
      this.$refs.updatePosition.openDialog(item);
    },
    addProducts(){
      this.$refs.addProducts.openDialog();
    },
    handlePageChange(index) {
      this.selectProductsList(index);
    },
    selectProductsList(index){
      if (index){
        this.query.pageIndex = index;
      }
      selectProducts(this.query).then(val=>{
        if (val.code == 200){
          this.productsList = val.data.records;
          this.pageTotal = val.data.total
        }
      })
    }
  },
  created() {
    this.selectProductsList();
    selectDictByType("productsClassify").then(val=>{
      this.productsClassify = val.data
    })
  }
}
</script>

<style scoped>
.code {
  margin-top: 8px;
  width: 80px;
  height: 38px;
  border-radius: 6px;
  background-color: #efefef;
}
</style>