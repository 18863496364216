<template>
  <el-dialog
      v-model="addProductsDialog"
      title="添加商品"
      width="1400px"
  >
    <div style="margin-right: 50px;height: 540px;overflow: auto">
      <el-form :model="products" :rules="productsRules" ref="productsRules" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="商品名称" prop="commodityName">
              <el-input style="width: 200px" v-model="products.commodityName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="itemWih" label="商品分类" prop="commodityType">
              <el-select style="width: 200px" v-model="products.commodityType">
                <el-option
                    style="width: 160px"
                    v-for="(item, index) in productsClassify"
                    :key="index"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="itemWih" label="商品库存" prop="inventory">
              <el-input style="width: 200px" v-model="products.inventory" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="上架日期" prop="startTime">
              <el-date-picker type="datetime" style="width:200px" value-format="YYYY/MM/DD HH:mm:ss" v-model="products.startTime"
                               placeholder="上架日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="下架日期" prop="endTime">
              <el-date-picker type="datetime" style="width:200px" value-format="YYYY/MM/DD HH:mm:ss" v-model="products.endTime"
                               placeholder="下架日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="itemWih" label="进货价格" prop="purchasingPrice">
              <el-input style="width: 200px" v-model="products.purchasingPrice" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="itemWih" label="市场价格" prop="bazaarPrice">
              <el-input style="width: 200px" v-model="products.bazaarPrice" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item class="itemWih" label="积分购买" prop="scorePrice">
              <el-input style="width: 200px" v-model="products.scorePrice" placeholder="只使用积分所需多少积分"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="itemWih" label="金额购买" prop="moneyPrice">
              <el-input style="width: 200px" v-model="products.moneyPrice" placeholder="只使用金额所需多少钱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="itemWih" label="积分&金额" prop="combineScorePrice">
              <el-input style="width: 200px" v-model="products.combineScorePrice" placeholder="结合金额还需要多少积分"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="itemWih" label="积分&金额" prop="combineMoneyPrice">
              <el-input style="width: 200px" v-model="products.combineMoneyPrice" placeholder="结合积分还需要多少金额"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="background-color: #F5F7FA;margin:10px 0 0 38px;padding: 10px 14px;border-radius: 10px">
              <div style="margin-bottom: 20px;font-weight: bold">商品图片</div>
                <el-upload
                    ref='upload'
                    class="upload"
                    accept="image/jpg,image/jpeg,image/png"
                    :http-request="uploadCardProduct"
                    :on-remove="(file, fileList) => {handleRemovePro(file, fileList)}"
                    :limit="9"
                    list-type="picture-card">
                 <el-button icon="Plus" text />
                </el-upload>
              </div>
          </el-col>
        </el-row>
        <div style="background-color: #F5F7FA;margin:30px 0px 0px 38px;padding: 10px 14px;border-radius: 10px;">
          <div style="margin-bottom: 20px;font-weight: bold">商品描述</div>
          <div id="websiteEditorElems" style="background: #ffffff;"></div>
        </div>
      </el-form>
    </div>
    <template #footer>

      <span class="dialog-footer">
        <el-button @click="addProductsDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import E from 'wangeditor'
import {selectDictByType} from  "@/api/dict"
import {uploadImage,uploadVideo} from '@/api/helpManual'
import {createdProducts}from "@/api/mall.js";
export default {
  name: "AddProducts",
  data() {
    return {
      addProductsDialog:false,
      productsClassify:[],
      colorList : ['#303133','#606266',
        '#909399','#A8ABB2','#C0C4CC',
        '#CDD0D6','#D4D7DE','#DCDFE6',
        '#E4E7ED','#EBEEF5','#F2F6FC',
        '#409EFF','#67C23A','#E6A23C',
        '#F56C6C','#909399'],
      products:{
        bazaarPrice:null,
        combineMoneyPrice:null,
        combineScorePrice:null,
        commodityName:null,
        commodityDetails:null,
        commodityType:null,
        endTime:null,
        inventory:null,
        purchasingPrice:null,
        startTime:null,
        pictures:[]
      },
      productsRules: {
        commodityName: [{
          required: true,
          message: '请输入商品名称！',
          trigger: 'blur'
        },],
        commodityType: [{
          required: true,
          message: '请选择商品分类！',
          trigger: 'change'
        },],
        inventory: [{
          required: true,
          message: '请输入商品库存！',
          trigger:'change'
        }],
        startTime: [{
          required: true,
          message: '请选择上架时间！',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请选择下架时间！',
          trigger: 'change'
        }],
        purchasingPrice: [{
          required: true,
          message: '请输入进货价格！',
          trigger: 'change'
        }],
        bazaarPrice: [{
          required: true,
          message: '请输入市场价格！',
          trigger: 'change'
        }],
        scorePrice: [{
          required: true,
          message: '请输入积分购买数量！',
          trigger: 'change'
        }],
        moneyPrice: [{
          required: true,
          message: '请输入金额购买数量！',
          trigger: 'change'
        }],
        combineScorePrice: [{
          required: true,
          message: '请输入积分&金额结合购买所需积分！',
          trigger: 'change'
        }],
        combineMoneyPrice: [{
          required: true,
          message: '请输入积分&金额结合购买所需金额！',
          trigger: 'change'
        }],
      },
    }
  },
  methods:{
    openDialog() {
      this.addProductsDialog = true;
      setTimeout(()=>{
        this.text();
      },1000)
    },
    dateFormat(val) {
      let myDate = new Date(val);
      let year = myDate.getFullYear(); //年
      let month = myDate.getMonth() + 1; //月
      let day = myDate.getDate(); //日
      let hh = myDate.getHours(); //时
      let mm = myDate.getMinutes();
      let ss = myDate.getSeconds();
      const value = year + "-" + month + "-" + day + " " + hh + ":" + mm + ":" + ss;
      return value;
    },
    confirm(){
      this.products.startTime = this.dateFormat(this.products.startTime);
      this.products.endTime = this.dateFormat(this.products.endTime);
      this.$refs.productsRules.validate((valid)=>{
        if(valid){
          this.products.commodityDetails = this.phoneEditor.txt.html();
          createdProducts(this.products).then(val=>{
            if (val.code == 200){
              this.$message.success(val.data);
              this.addProductsDialog = false;
            }else {
              this.$message.warning(val.message);
            }
          })
        }
      })
    },
    handleRemovePro(file){
      let fileName = file.name;
      let list = this.products.pictures;
      this.products.pictures = list.filter(item => item.name !== fileName);
    },
    async uploadCardProduct(file) {
      const fileName = file.file.name;
      const lastIndex = fileName.lastIndexOf(".");
      if (lastIndex !== -1) {
        const extension = fileName.substring(lastIndex + 1);
        let a = ['png', 'jpg', 'jpeg','PNG', 'JPG', 'JPEG'];
        if (a.includes(extension)) {
          await this.uploadImg(file,fileName)
        }else {
          this.$message.warning('图片格式有误！');
        }
      }
    },
    async uploadImg(file,fileName) {
      let url = null
      const data = new FormData()
      data.append(
          'multipartFile', file.file
      )
      data.append(
          'flag', 'card'
      )
      data.append(
          'fileName', file.file.name
      )
      await uploadImage(data).then(res => {
        if (res.code == 200) {
          url = res.data;
          let item = {url:res.data,name:fileName};
          this.products.pictures.push(item);
        } else {
          this.$message.warning('图片上传失败');
        }
      })
      return url
    },
    text(){
      // 绑定div上的IDwebsiteEditorElem
      this.phoneEditor = new E('#websiteEditorElems')
      this.colorList.forEach(item=>{
        this.phoneEditor.config.colors.push(item)
      })
      // 获取输入的内容
      this.phoneEditor.config.onchange = (html) => {
        // 打印输入的内容
        console.log(html);
      }
      // 上传图片到服务器，base64形式
      this.phoneEditor.config.uploadImgShowBase64 = false
      this.phoneEditor.config.customUploadImg = function (file, insertImgFn) {
        // console.log(file[0]);
        const data = new FormData()
        let fileName = 'helpManual/'
        data.append(
            'multipartFile', file[0]
        )
        data.append(
            'fileName', fileName
        )
        data.append(
            'flag', 'helpManual'
        )

        uploadImage(data).then(res => {
          let url = res.data
          insertImgFn(url)
        })
      },
      //改变此方法才可以进行自定义上传，不输入地址
      this.phoneEditor.config.customUploadVideo = (files, insertImgFn) => {
        const data = new FormData()
        data.append(
            'multipartFile', files[0]
        )
        data.append(
            'fileName', files[0].name
        )
        data.append(
            'flag', 'helpManual'
        )
        uploadVideo(data).then(res => {
          // console.log(res);
          let url = res.data
          insertImgFn(url)
        })
      }
      // 隐藏网络图片
      this.phoneEditor.config.showLinkImg = false
      // 创建一个富文本编辑器
      this.phoneEditor.create()
      // 富文本内容
      this.phoneEditor.txt.html()
    }
  },
  mounted() {

  },
  created() {
    selectDictByType("productsClassify").then(val=>{
      this.productsClassify = val.data
    })
  }
}
</script>

<style scoped>

</style>